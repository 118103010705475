import React from "react";
import Sidebar from "./Sidebar";
import NewSidebar from "./NewSidebar";

const LayoutWrapper = ({ children }) => {
  return (
    <div className="row">
      <div className="col-2 new_sidebar_col">
        {/* <Sidebar /> */}
        <NewSidebar />
      </div>
      <div className="col-xl-10 col-12 p-0" style={{ background: "#fbfbfb" }}>
        {children}
      </div>
    </div>
  );
};

export default LayoutWrapper;
