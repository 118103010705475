import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoChevronDown } from "react-icons/io5";
import axios from "../../axios";
import { notifySuccess } from "../../actions/customFn";
import SmallLoader from "../../utils/SmallLoader";

const HierarchyModal = ({ modalShow, setModalShow, setIsUpdate }) => {
  const [allDepartment, setAllDepartment] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [parentDepartment, setParentDepartment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const allDepartment = async () => {
      const url = "sales/company-structure/all-department";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };
      try {
        const res = await axios.get(url, config);
        setAllDepartment(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    allDepartment();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = "sales/company-structure/create-department";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const data = {
      department_name: departmentName.trim(),
      departmentId: parentDepartment,
    };
    try {
      const res = await axios.post(url, data, config);
      console.log(res.data.message);
      setIsUpdate((p) => !p);
      notifySuccess(res.data.message);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        centered
        className="bootstrap-modal-custom comment-modal hierarchy_modal_container"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Body>
          <h2>Add Department</h2>
          <form onSubmit={handleSubmit}>
            <div className="hierarchy_modal_input">
              <h3>Department Name</h3>
              <input
                value={departmentName}
                onChange={(e) => setDepartmentName(e.target.value)}
                type="text"
                placeholder="Accounts Department"
              />
            </div>
            <div className="hierarchy_modal_input">
              <h3>Parent Department</h3>     
              <select
                value={parentDepartment}
                onChange={(e) => setParentDepartment(e.target.value)}
              >
                <option value={""}>Select Department</option>
                {allDepartment.map((item, i) => (
                  <option value={item._id}>{item.department_name}</option>
                ))}
              </select>
              <IoChevronDown className="dropdown_icon" />
            </div>

            <div className="hierarchy_submit_btn">
              <button type="submit" className="save_btn" disabled={isLoading}>
                {isLoading ? <SmallLoader color={"#fff"}/> : "Save" }
                
              </button>
              <button
                onClick={() => setModalShow(false)}
                className="cancel_btn"
              >
                Cancel
              </button>
            </div>
          </form>
          {/*  */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HierarchyModal;
