import React from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ImportLeadFile from "../components/ImportLead/ImportLeadFile";

const ImportLeads = () => {
  //   const [state, useState] = useState(false);
  return (
    <>
      <LayoutWrapper>
        <Header />
        <ImportLeadFile />
      </LayoutWrapper>
    </>
  );
};

export default ImportLeads;
