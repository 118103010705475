import React, { useEffect, useRef, useState } from "react";

import { FaAngleDown } from "react-icons/fa6";

import axios from "../../axios";
import { useParams } from "react-router-dom";
import { notifySuccess, useOutsideClick } from "../../actions/customFn";
import TransparentLoader from "../../utils/TransparentLoader";

const FormStudentHeader = ({ formData, setIsUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const optionRef = useRef(null);
  const [isCheck, setIsCheck] = useState(false);

  // id, claimable.

  const leadStatus = (status) => {
    setIsLoading(true);
    const url = "/leads/claim-status";

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const data = {
      id,
      claimable: status,
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsLoading(false);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsCheck(formData.claimable);
  }, [formData]);

  useOutsideClick(optionRef, setIsOpen);

  return (
    <>
      {isLoading && <TransparentLoader />}
      <div className="formstudent_header_container">
        <div className="formstudent_header_left">
          <h2>{formData.username}</h2>
          <h4>{formData.program_name}</h4>
        </div>
        <div className="formstudent_header_right">
          {localStorage.getItem("oeson_role") === "SalesManager" && (
            <div className="formstudent_header_option" ref={optionRef}>
              <div
                className="formstudent_header_select cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
              >
                <span>Lead Status</span>
                <FaAngleDown />
              </div>
              {isOpen && (
                <div className="formstudent_header_options">
                  <div
                    className="formstudent_header_options_radio"
                    onClick={() => leadStatus(true)}
                  >
                    <input type="radio" checked={isCheck ? true : false} />
                    <label>Enable</label>
                  </div>
                  <div
                    className="formstudent_header_options_radio"
                    onClick={() => leadStatus(false)}
                  >
                    <input
                      type="radio"
                      checked={isCheck === false ? true : false}
                    />
                    <label>Disable</label>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FormStudentHeader;
