import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "../../utils/Loader";
import axios from "../../axios";
import ManagerEvalModal from "./ManagerEvalModal";
import { useSelector } from "react-redux";
import { notifyDanger } from "../../actions/customFn";

const ManagerEvalTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [evaluationArr, setEvaluationArr] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const getEvaluationData = () => {
    const url = `/leads/get-conflicted-leads`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        console.log(data);
        setEvaluationArr(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response);
      });
  };

  useEffect(() => {
    getEvaluationData();
  }, [isUpdate]);

  return (
    <>
      <div className="module_student_table table-responsive scrollbar-template">
        {isLoading ? (
          <Loader Height={"40vh"} />
        ) : (
          <table className="table-template-style">
            <thead>
              <tr>
                <th scope="col" className="table-first-element">
                  Sr no.
                </th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone no.</th>
                <th scope="col">Program</th>
                <th scope="col">Country</th>
              </tr>
            </thead>
            <tbody>
              {evaluationArr.map((data, i) => {
                return (
                  <TableItem data={data} index={i} setIsUpdate={setIsUpdate} />
                );
              })}
            </tbody>
          </table>
        )}

        {/* <h4 className="text-center text-black mt-4">No Students Found!</h4> */}
      </div>
    </>
  );
};

export default ManagerEvalTable;

const TableItem = ({ index, data, setIsUpdate }) => {
  const [modalShow, setModalShow] = useState(false);
  const profileData = useSelector((state) => state.profileData.userData);

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td
        className="cursor-pointer lead-eval-table-td"
        onClick={() => {
          const isExist = data.manager_evaluations.find(
            (item) => item.evaluatedBy === profileData._id
          );
          if (isExist) {
            notifyDanger("you have allready evaluated!");
          } else {
            setModalShow(true);
          }
        }}
      >
        {data.username}
      </td>
      <td>{data.email}</td>
      <td>{data.contact_no}</td>
      <td>{data.program_name ? data.program_name : "Not Found!"}</td>
      <td>{data.country ? data.country : "Not Found!"}</td>
      {modalShow && (
        <ManagerEvalModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          modalData={data}
          setIsUpdate={setIsUpdate}
        />
      )}
    </tr>
  );
};
