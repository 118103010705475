import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import FormStudentNav from "../components/FormStudent/FormStudentNav";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import FormStudentInfo from "../components/FormStudent/FormStudentInfo";
import FormStudentHeader from "../components/FormStudent/FormStudentHeader";
import { useParams } from "react-router-dom";
import axios from "../axios";

const FormStudent = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const url = `/leads/get-student/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setFormData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isUpdate]);

  return (
    <LayoutWrapper>
      <Header />
      <FormStudentHeader formData={formData} setIsUpdate={setIsUpdate} />
      <FormStudentNav formData={formData} />
      <FormStudentInfo formData={formData} />
    </LayoutWrapper>
  );
};

export default FormStudent;
