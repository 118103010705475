import { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import axios from "../../axios";
import SmallLoader from "../../utils/SmallLoader";

const ResourcesModal = ({ modalShow, setModalShow, setIsUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [folderName, setFolderName] = useState("");

  const createFolder = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = "/sales/create-resource-folder";
    const data = {
      folder_name: folderName,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsLoading(false);
        setModalShow(false);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        notifyDanger(err?.response?.data?.message || "Some Error Occured");
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      className="create-module-modal bootstrap-modal-custom"
      show={modalShow}
      onHide={() => setModalShow(false)}
      centered
    >
      <Modal.Body>
        <div className="create-module-modal-body">
          <AiOutlineClose
            className="modal-close-btn"
            onClick={() => setModalShow(false)}
          />
          <h2>Create Folder</h2>
          <form onSubmit={createFolder}>
            <label>Folder Name </label>
            <input
              placeholder="Enter Folder Name"
              type="text"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              required
            />
            <div className="text-center">
              <button>{isLoading ? <SmallLoader color={"#fff"} /> : "done"}</button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ResourcesModal;
