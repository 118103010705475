import React, { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

const OperationOfferLetterTable = () => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>
              <input type="checkbox" />
            </th>
            <th>Name</th>
            <th>Email address</th>
            <th>Phone Number</th>
            <th>Domain</th>
            <th>Created At</th>
            <th>Country</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input type="checkbox" />
            </td>
            <td className="table_name">abhishek sharma</td>
            <td>abhi365@gmail.com</td>
            <td>8989551553</td>
            <td>MERN STACK DEVELOPER</td>
            <td>9/16/2023, 1:03:33 AM</td>
            <td>india</td>
            <td className="table_icon">
              <RiDeleteBinLine />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default OperationOfferLetterTable;
