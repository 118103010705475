import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import { FaChevronDown, FaChevronLeft, FaChevronUp } from "react-icons/fa6";
import { BiSearch, BiSolidMessage } from "react-icons/bi";
import { FiMessageSquare } from "react-icons/fi";
import axios from "../axios";
import { notifyDanger } from "../actions/customFn";

const TicketChats = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ticketData, setTicketData] = useState([]);

  const handleEvent = () => {
    setIsOpen(!isOpen);
  };

  const getAllTickets = () => {
    const url = `/qna/all-tickets`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setTicketData(data);
      })
      .catch((err) => {
        console.log(err);
        notifyDanger(err.response.data.message || err.response);
      });
  };


  useEffect(()=>{
    getAllTickets()
  },[])


  // console.log(ticketData);
  

  return (
    <>
      <LayoutWrapper>
        <Header />
        <div className="ticket_chats_container">
          <div className="ticket_chats_header">
            <h2>
              <FaChevronLeft /> Ticket Queries
            </h2>
          </div>

          <div className="ticket_chats_div">
            <div className="ticket_chats_list">
              <div className="ticket_chats_list_header">
                <div className="ticket_chats_search_input">
                  <input type="search" placeholder="Search" />
                  <BiSearch className="search_icon" />
                </div>
              </div>
              <div className="ticket_chats_listitem active">
                <div className="ticket_chats_listitem_user">K</div>
                <div className="ticket_chats_listitem_user_chat_detail">
                  <div className="ticket_chats_listitem_badge_div">
                    <span>Overdue by 3 days</span>
                    <span>Customer responded 1h ago</span>
                  </div>
                  <p>
                    THW - New email template creation <span>#6493</span>
                  </p>
                  <div className="ticket_chats_listitem_author_name">
                    <FiMessageSquare style={{ marginRight: "3px" }} />
                    Darren Adams
                    <div className="dot"></div>
                    Headboard Workshop
                  </div>
                </div>
              </div>
              <div className="ticket_chats_listitem">
                <div className="ticket_chats_listitem_user">K</div>
                <div className="ticket_chats_listitem_user_chat_detail">
                  <div className="ticket_chats_listitem_badge_div">
                    <span>Overdue by 3 days</span>
                    <span>Customer responded 1h ago</span>
                  </div>
                  <p>
                    THW - New email template creation <span>#6493</span>
                  </p>
                  <div className="ticket_chats_listitem_author_name">
                    <FiMessageSquare style={{ marginRight: "3px" }} />
                    Darren Adams
                    <div className="dot"></div>
                    Headboard Workshop
                  </div>
                </div>
              </div>
              <div className="ticket_chats_listitem">
                <div className="ticket_chats_listitem_user">K</div>
                <div className="ticket_chats_listitem_user_chat_detail">
                  <div className="ticket_chats_listitem_badge_div">
                    <span>Overdue by 3 days</span>
                    <span>Customer responded 1h ago</span>
                  </div>
                  <p>
                    THW - New email template creation <span>#6493</span>
                  </p>
                  <div className="ticket_chats_listitem_author_name">
                    <FiMessageSquare style={{ marginRight: "3px" }} />
                    Darren Adams
                    <div className="dot"></div>
                    Headboard Workshop
                  </div>
                </div>
              </div>
            </div>

            <div className="ticket_chats_box">
              <div className="ticket_chats_box_header">
                <div className="ticket_chats_box_user_div">D</div>
                <BiSolidMessage style={{ marginRight: "5px" }} />
                Darren Adams
              </div>
              <div className="ticket_chats_box_container">
                <div className="ticket_chats_box_receiver">
                  <div className="ticket_chats_box_receiver_profile">D</div>
                  <div className="ticket_chats_box_receiver_message">
                    UI/UX Designer Needs...
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad
                </p>
                <span>3 days ago</span>

                <div className="ticket_chats_box_receiver ticket_chats_box_giver">
                  <div className="ticket_chats_box_receiver_profile">D</div>
                  <div className="ticket_chats_box_receiver_message">
                    UI/UX Designer Needs...
                  </div>
                </div>
                <p className="ticket_chats_box_giver_reply">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad
                </p>
                <span className="ticket_chats_box_giver_last_seen">
                  3 days ago
                </span>

                <div className="ticket_chat_box_textarea">
                  <textarea />
                  <button>Send</button>
                </div>
              </div>
            </div>

            <div className="ticket_chats_user_details">
              <div className="ticket_chats_user_details_header">
                User Details
              </div>
              <div className="ticket_chats_user_details_div">
                <div className="ticket_chats_user_contact_box">
                  <div className="contact_box_header">Contact Details</div>
                  <div className="ticket_chats_user_contact_box_content">
                    <span>
                      <h5>Name</h5>
                      <p>Darren Adams</p>
                    </span>
                    <span>
                      <h5>Email</h5>
                      <p>danadams@gmail.com</p>
                    </span>
                    <span>
                      <h5>Phone</h5>
                      <h6>Add Phone</h6>
                    </span>
                  </div>
                </div>

                <div className="ticket_chats_user_ticket_dropdown">
                  <div className="ticket_chats_user_ticket_dropdown_header">
                    Tickets
                  </div>

                  <div className="ticket_chats_user_ticket_dropdown_content">
                    <div className="ticket_chats_user_ticket_dropdown_item">
                      <h4>Status</h4>
                      <li onClick={handleEvent}>
                        pending <FaChevronDown />
                      </li>
                    </div>

                    {isOpen && (
                      <div
                        className={
                          "ticket_chats_user_ticket_dropdown_item_list"
                        }
                      >
                        <h4 onClick={() => setIsOpen(!isOpen)}>
                          Status <FaChevronUp />
                        </h4>
                        <li>
                          Pending <input type="checkbox" />
                        </li>
                        <li>
                          Closed <input type="checkbox" />
                        </li>
                        <li>
                          Answered <input type="checkbox" />
                        </li>
                      </div>
                    )}

                    <div className="ticket_chats_user_ticket_dropdown_item">
                      <h4>Priority</h4>
                      <li>Medium</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutWrapper>
    </>
  );
};

export default TicketChats;
