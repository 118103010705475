import React, { useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import { images } from "../actions/customFn";
import { IoChevronDown } from "react-icons/io5";

const ImportLeadPreview = () => {
  
  return (
    <>
      <LayoutWrapper>
        <Header />
        <div className="import_leads_result_header">
          <h2>Import Results</h2>
          <span>Completed On Jul 29, 2024 04:35 PM</span>
        </div>
        <div className="import_leads_result_container">

          <div className="import_leads_result_file_div">
            <h3>File</h3>
            <div className="import_leads_result_file_div_img">
              <img src={images["file_pdf.png"]} alt="" />
              Document name
            </div>
          </div>

          <div className="import_leads_result_field_div">
            <h3>Field Mapping</h3>
            <div className="import_leads_result_field_content">
              <div className="import_leads_result_field_content_header">
                <h4>Fields from file</h4>
                <h4>CRM Fields</h4>
              </div>
              <div className="import_leads_result_field_data">
                <p>Sr. No. (Col:1)</p>
                <div className="import_leads_result_field_data_select">
                  <select>
                    <option>Yet to map</option>
                  </select>
                  <IoChevronDown className="down_icon" />
                </div>
              </div>
              <div className="import_leads_result_buttons">
                <button className="save_btn">Save</button>
                <button className="cancel_btn">Cancel</button>
              </div>
            </div>
          </div>

        </div>
      </LayoutWrapper>
    </>
  );
};

export default ImportLeadPreview;
