import React, { useState } from "react";
import { images } from "../../actions/customFn";
import { MdEmail } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { asyncLogin } from "../../actions/loginAction";
import SmallLoader from "../../utils/SmallLoader";
// import SmallLoader from "../../utils/SmallLoader";

const LoginForm = () => {
  const [type, setType] = useState("password");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedInDevice, setIsLoggedInDevice] = useState(false);

  const initialState = () => {
    setEmail("");
    setPassword("");
  };

  const LoginSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      email,
      password,
      loginType: "SalesPanel",
    };
    dispatch(
      asyncLogin(
        data,
        navigate,
        initialState,
        setIsLoading,
        setIsLoggedInDevice
      )
    );
  };

  const LoginOtherDevice = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      email,
      password,
      loginType: "SalesPanel",
      isLoggedIn: true,
    };
    dispatch(asyncLogin(data, navigate, initialState, setIsLoading));
  };

  return (
    <div className="loginform_container">
      <div className="loginform_logo">
        <img alt="" src={images["oeson-blue.png"]} />
      </div>
      <div className="loginform_title">
        <h2>Log In</h2>
      </div>
      <form onSubmit={LoginSubmit}>
        <div className="loginform_inp">
          <MdEmail />
          <input
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="loginform_inp">
          <IoIosLock />
          <input
            placeholder="Password"
            type={type}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {type === "password" ? (
            <IoEye onClick={() => setType("text")} className="cursor-pointer" />
          ) : (
            <IoEyeOff
              onClick={() => setType("password")}
              className="cursor-pointer"
            />
          )}
        </div>
        <div className="loginform_inp_forgot">
          {/* <NavLink to={"#"}>Forgot password?</NavLink> */}
        </div>
        <div className="loginform_submit">
          <button disabled={isLoading}>
            {isLoading ? <SmallLoader color={"#fff"} /> : "Log In"}{" "}
          </button>
        </div>
      </form>
      {isLoggedInDevice && (
        <div className="loginform_submit loginform_submit_other_device">
          <button disabled={isLoading} onClick={LoginOtherDevice}>
            {isLoading ? (
              <SmallLoader color={"#fff"} />
            ) : (
              "Logout from Other Device"
            )}{" "}
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
