import React, { useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import { images } from "../../actions/customFn";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";
import { FiMenu } from "react-icons/fi";
import Sidebar from "./Sidebar";
import NewSidebar from "./NewSidebar";
// import MobileSidebar from "./MobileSidebar";

const Header = () => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  const Dummy_ARR = [
    {
      text: "OESON",
      url: -1,
    },
    {
      text: "Home",
      url: "#",
    },
    {
      text: "Batch",
      url: "#",
    },
  ];

  const location = useLocation();

  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profileData.userData);

  // const video_notification = useSelector(
  //   (state) => state.notificationData.video_notification
  // );

  const notificationRef = useRef(null);

  // const closeWindow = (value) => {
  //   dispatch(setNotificationOpen(value));
  // };

  // useOutsideClick(notificationRef, closeWindow);

  function checkString(string) {
    var pattern = /\d/;
    if (pattern.test(string)) {
      return true;
    } else {
      return false;
    }
  }

  const PathStructure = () => {
    const pathArr = location.pathname.split("/");
    const pathArrObj = pathArr.map((path, i) => {
      if (path !== "") {
        if (checkString(path)) {
          return null;
        } else if (checkString(pathArr[i] + 1) === false) {
          return {
            text: path.toUpperCase(),
            url: window.location.origin + pathArr.slice(0, i + 1).join("/"),
          };
        } else if (checkString(pathArr[i + 1])) {
          return {
            text: path.toUpperCase(),
            url: window.location.origin + pathArr.slice(0, i + 2).join("/"),
          };
        } else {
          if (i === pathArr.length - 1) {
            return {
              text: path.toUpperCase(),
              url: window.location.origin + pathArr.slice(0, i + 1).join("/"),
            };
          } else {
            return {
              text: path.toUpperCase(),
              url: -1,
            };
          }
        }
      } else {
        return {
          text: "Home",
          url: window.location.origin + pathArr.slice(0, i + 1).join("/"),
        };
      }
    });
    let filterArr = pathArrObj.filter((val) => val !== null);
    if (location.pathname === "/") {
      filterArr = [{ text: "Home", url: window.location.origin }];
    }
    return filterArr;
  };

  const pathArr = PathStructure();

  return (
    <div className="header_container">
      <div className="header_breadcrumb">
        {pathArr?.length > 0 &&
          pathArr.map((data, i) => {
            return (
              <NavLink to={data.url} key={i}>
                <FaAngleRight />
                <span>{data.text}</span>
              </NavLink>
            );
          })}
      </div>
      <div className="header_profile_details">
        <div
          className={`header_profile_bellicon ${
            // video_notification?.uploadedpercent !== 0 ? "active" : ""
            ""
          }`}
        >
          <div
            className="header_profile_bellicon_svg"
            // onClick={() => dispatch(setNotificationOpen(true))}
          >
            {/* <BellIcon /> */}
          </div>
          {/* {isNotification && (
            <NotificationCard
              notificationRef={notificationRef}
              video_notification={video_notification}
            />
          )} */}
        </div>
        <NavLink to={"#"}>
          <img
            alt=""
            src={profileData.image}
            className="header_profile_details_img"
          />
        </NavLink>
        <div className="header_profile_humberger">
          <FiMenu size={25} onClick={handleToggle} />
          {open ? <NewSidebar open={open} setOpen={setOpen} /> : ""}
          {/* {open ? <MobileSidebar open={open} setOpen={setOpen} /> : ""} */}
        </div>
      </div>
    </div>
  );
};

export default Header;

const NotificationCard = ({ notificationRef, video_notification }) => {
  return (
    <div className="notification_card_container" ref={notificationRef}>
      {video_notification.uploadedpercent === 0 ? (
        <h6 className="text-center mb-3 mt-3">No Notification</h6>
      ) : (
        video_notification &&
        video_notification.uploadedpercent !== 0 && (
          <div className="notification_card_course">
            <div className="notification_card_course_title">
              <h4>{video_notification.title}</h4>
              {/* <AiOutlineClose /> */}
            </div>
            <div className="notification_card_course_info">
              <span>{video_notification.uploadedpercent}% Uploaded</span>
              <span>
                estimated time:{" "}
                {video_notification.estimated_time_in_min !== 0 &&
                  `${video_notification.estimated_time_in_min} minutes `}
                {video_notification.estimated_time_in_sec !== 0 &&
                  `${video_notification.estimated_time_in_sec} seconds `}
              </span>
            </div>
            <ProgressBar
              completed={video_notification.uploadedpercent}
              bgColor="#024c87"
              height="10px"
              borderRadius="6px"
              isLabelVisible={false}
            />
          </div>
        )
      )}
    </div>
  );
};
