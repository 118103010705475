import React from "react";
import { FaChevronLeft } from "react-icons/fa6";

const ImportLeadFile = () => {
  return (
    <>
      <div className="import_leads_header">
        <span>
          <FaChevronLeft />
          Import Leads
        </span>
        <button>import</button>
      </div>

      <div className="import_leads_container">
        <div className="import_leads_file_select">
          <h2>Select File</h2>
          <div className="import_leads_input">
            <p>Choose a file to import</p>
            <label htmlFor="fileInput">Select File</label>
            <input id="fileInput" className="d-none" type="file" />
          </div>
        </div>
        <div className="import_leads_file_select import_leads_field_mapping">
          <h2>Field Mapping</h2>
          <div className="import_leads_input">
            <p>Choose a file to import</p>
            <label htmlFor="fileInput">Map Fields</label>
            <input id="fileInput" className="d-none" type="file" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportLeadFile;
