import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import moment from "moment";
import { FaCircleCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { notifyDanger, notifySuccess } from "../../actions/customFn";

const FormStudentInfo = ({ formData }) => {
  const [remarkTxt, setRemarkTxt] = useState("");

  const submitData = (e) => {
    e.preventDefault();
    const url = "leads/update-lead-remark";
    const data = {
      email: formData.email,
      remark: remarkTxt,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setRemarkTxt("")
      })
      .catch((err) => {
        notifyDanger(err?.response?.data?.message || "Some Error Occured");
        console.log(err);
      });
  };
  return (
    <div className="formstudent_info_container">
      <div className="row">
        <div className="col-6">
          <div className="formstudent_info_left">
            <div className="formstudent_info_heading">
              <h2>Personal Information</h2>
            </div>
            <div className="formstudent_info_details">
              <div className="formstudent_info_detail_item">
                <h3>Intern Code</h3>
                <h4>{formData.intern_code}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Enroll At</h3>
                <h4>{moment(formData.createdAt).format("DD MMM YYYY LT")}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Email</h3>
                <h4>{formData.email}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Phone</h3>
                <h4>{formData.contact_no}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Address</h3>
                <h4>{formData.address}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Country</h3>
                <h4>{formData.country}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Program</h3>
                <h4>{formData.program_name}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Batch</h3>
                <h4>{formData.batch_name}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="formstudent_info_right"></div>
        </div>
        <div className="col-6">
          <div className="formstudent_info_bottom">
            <div className="formstudent_info_bottom_heading">
              <h2> Remarks</h2>
              <BiEditAlt />
            </div>
            <form onSubmit={submitData}>
              <div className="formstudent_info_bottom_div">
                <textarea
                  placeholder="Enter Remark"
                  rows={"3"}
                  required
                  value={remarkTxt}
                  onChange={(e) => setRemarkTxt(e.target.value)}
                />
                <div className="d-flex mt-2 justify-content-end">
                  <button>
                    <FaCircleCheck className="formstudent_info_bottom_svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStudentInfo;
