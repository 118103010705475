import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiEdit2 } from "react-icons/fi";

const OperationUploadModal = ({ modal, setModal }) => {
 
  return (
    <>
      <Modal centered show={modal} onHide={() => setModal(false)}>
        <Modal.Body>
          <div className="operation_upload_modal">
            <h1>
              Offer Letter
              {/* <FiEdit2 /> */}
            </h1>
            <div className="operation_upload_data_input">
              <div className="operation_upload_data_inputdiv">
                <label htmlFor="">Name</label>
                <input type="text" placeholder="Soumya" />
              </div>
              <div className="operation_upload_data_inputdiv">
                <label htmlFor="">Email</label>
                <input type="email" placeholder="Soumya123@Gmail.com" />
              </div>
              <div className="operation_upload_data_inputdiv">
                <label htmlFor="">Address</label>
                <input type="text" placeholder="Indore" />
              </div>
              <div className="operation_upload_data_inputdiv">
                <label htmlFor="">Select Program</label>
                <input type="text" placeholder="Data Science" />
              </div>
              <div className="operation_upload_data_inputdiv">
                <label htmlFor="">Phone</label>
                <input type="phone" placeholder="+91 8989551553" />
              </div>
              <div className="operation_upload_data_inputdiv">
                <label htmlFor="">Joining Date</label>
                <input type="date" />
              </div>
              <div className="operation_upload_data_inputdiv">
                <label htmlFor="">Country</label>
                <select name="" id="">
                  <option value="">Select a Country</option>
                </select>
                {/* <input type="text" placeholder="India" /> */}
              </div>
              <div className="operation_upload_data_inputdiv">
                <label htmlFor="">Gender</label>
                <select name="" id="">
                  <option value="">Select a Gender</option>
                  <option value="">Male</option>
                  <option value="">Female</option>
                </select>
                {/* <input type="text" placeholder="female" /> */}
              </div>
              <button>Send Offer Letter</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OperationUploadModal;
