import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ResourcesNav from "../components/resources/ResourcesNav";
import ResourcesView from "../components/resources/ResourcesView";
import { notifyDanger } from "../actions/customFn";
import axios from "../axios";
import { useParams } from "react-router-dom";

const ResourcesUpload = () => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const { id } = useParams();

  const getFileList = () => {
    setIsLoading(true);
    const url = `/sales/get-resource/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        console.log(data);
        setIsLoading(false);
        setFileList(data);
      })
      .catch((err) => {
        notifyDanger(err?.response?.data?.message || "Some Error Occured");
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getFileList();
  }, [isUpdate]);

  return (
    <>
      <LayoutWrapper>
        <Header />
        <ResourcesNav setIsUpdate={setIsUpdate} />
        <ResourcesView fileList={fileList} setIsUpdate={setIsUpdate} />
      </LayoutWrapper>
    </>
  );
};

export default ResourcesUpload;
