import React, { useEffect, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { MdEditDocument } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import HierarchyModal from "./HierarchyModal";
import axios from "../../axios";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import TransparentLoader from "../../utils/TransparentLoader";

const CompanyHierarchy = () => {
  const [modalShow, setModalShow] = useState(false);
  const [hierarchyData, setHierarchyData] = useState([]);
  const [subdepartments, setSubdepartments] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [existingDepartment, setExistingDepartment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // console.log(hierarchyData, "hie");

  useEffect(() => {
    const getDepartment = async () => {
      const url = "sales/company-structure/get-department";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };
      try {
        const res = await axios.get(url, config);
        setHierarchyData(res.data.data);
        setSubdepartments(res.data.data.subdepartments);
      } catch (error) {
        console.log(error);
      }
    };
    getDepartment();
  }, [isUpdate]);

  const addUserToDepartment = (ID) => {
    if (ID === existingDepartment) {
      return;
    } else {
      setIsLoading(true);
      const url = "sales/company-structure/add-user";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };

      const data = {
        userId: currentUserId,
        departmentId: ID,
        existingdepartment: existingDepartment,
      };

      axios
        .post(url, data, config)
        .then((res) => {
          const { message } = res.data;
          notifySuccess(message);
          setIsLoading(false);
          setIsUpdate((p) => !p);
          setCurrentUserId("");
        })
        .catch((err) => {
          setIsLoading(false);
          notifyDanger(err?.response?.data?.message || "Some error occured!");
        });
    }
  };

  return (
    <>
      {isLoading && <TransparentLoader />}
      <div className="company_header_container">
        <div className="company_hierarchy_header">
          <h2>
            <HiOutlineChevronLeft />
            Company Hierarchy
          </h2>
          <button onClick={() => setModalShow(true)}>Add Department</button>
        </div>

        <div className="container">
          <div className="company_hierarchy_container">
            <div className="company_hierarchy_div">
              <div
                className="company_hierarchy_main_box"
                onClick={() => addUserToDepartment(hierarchyData._id)}
              >
                <div className="company_hierarchy_main_box_header">
                  <h2>{hierarchyData.department_name}</h2>
                </div>
                <div className="company_hierarchy_main_box_user scrollbar-template">
                  {hierarchyData.userIds?.map((data, i) => (
                    <div
                      key={i}
                      className="company_hierarchy_main_box_user_div"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "4px " }}
                      >
                        <img src={data.image} alt="" />
                        <p>{data.username}</p>
                      </div>
                      <input
                        type="radio"
                        checked={currentUserId === data._id}
                        onChange={() => {
                          setCurrentUserId(data._id);
                          setExistingDepartment(hierarchyData._id);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="company_hierarchy_box_container">
                {subdepartments.map((data, i) => {
                  return (
                    <div
                      className="company_hierarchy_box"
                      onClick={() => addUserToDepartment(data._id)}
                    >
                      <div className="company_hierarchy_box_header">
                        <h3>
                          {data.department_name}
                          <span>
                            {/* <MdEditDocument /> */}
                            <RxCross2 className="cursor-pointer" />
                          </span>
                        </h3>
                      </div>

                      {data.userIds.map((item, ind) => {
                        return (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            key={ind}
                            className="company_hierarchy_main_box_user_div d-flex justify-content-between"
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{ gap: "4px " }}
                            >
                              <img src={item.image} alt="" />
                              <p>{item.username}</p>
                            </div>
                            <input
                              type="radio"
                              checked={currentUserId === item._id}
                              onChange={() => {
                                setCurrentUserId(item._id);
                                setExistingDepartment(data._id);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalShow && (
        <HierarchyModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          setIsUpdate={setIsUpdate}
        />
      )}
    </>
  );
};

export default CompanyHierarchy;
