import React, { createContext, useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import FormTableHeader from "../components/formTables/FormTableHeader";
import FormTableNavs from "../components/formTables/FormTableNavs";
import FormTableComponent from "../components/formTables/FormTableComponent";
import { useLocation } from "react-router-dom";
import PaymentdataTable from "../components/formTables/PaymentdataTable";
import EnrolldataTable from "../components/formTables/EnrolldataTable";
import InvoiceDataTable from "../components/formTables/InvoiceDataTable";
import ContactUsTable from "../components/formTables/ContactUsTable";
import OgtipTable from "../components/formTables/OgtipTable";
import EnquiryTable from "../components/formTables/EnquiryTable";
import BrochureTable from "../components/formTables/BrochureTable";

export const FormContext = createContext();

const FormTable = () => {
  const { pathname } = useLocation();
  const [searchInp, setSearchInp] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [rowsLimit, setRowsLimit] = useState(10);

  useEffect(() => {
    setIsUpdate((p) => !p);
  }, [filterOptions, rowsLimit]);

  return (
    <FormContext.Provider value={{ setFilterOptions, filterOptions }}>
      <LayoutWrapper>
        <Header />
        <div className="forms_table_container scrollbar-template">
          {/* <FormTableHeader /> */}
          <FormTableNavs
            searchInp={searchInp}
            setSearchInp={setSearchInp}
            setIsUpdate={setIsUpdate}
            setRowsLimit={setRowsLimit}
            rowsLimit={rowsLimit}
          />

          {pathname === "/forms/payment-data" && (
            <PaymentdataTable
              searchInp={searchInp}
              isUpdate={isUpdate}
              rowsLimit={rowsLimit}
            />
          )}
          {pathname === "/forms/enroll-data" && (
            <EnrolldataTable
              searchInp={searchInp}
              isUpdate={isUpdate}
              rowsLimit={rowsLimit}
            />
          )}
          {pathname === "/forms/invoice-data" && (
            <InvoiceDataTable
              searchInp={searchInp}
              isUpdate={isUpdate}
              rowsLimit={rowsLimit}
            />
          )}
          {pathname === "/forms/contactus-data" && (
            <ContactUsTable
              searchInp={searchInp}
              isUpdate={isUpdate}
              rowsLimit={rowsLimit}
            />
          )}
          {pathname === "/forms/ogtip-data" && (
            <OgtipTable
              searchInp={searchInp}
              isUpdate={isUpdate}
              rowsLimit={rowsLimit}
            />
          )}
          {pathname === "/forms/enquiry-data" && (
            <EnquiryTable
              searchInp={searchInp}
              isUpdate={isUpdate}
              rowsLimit={rowsLimit}
            />
          )}

          {pathname === "/forms/brochure-data" && (
            <BrochureTable
              searchInp={searchInp}
              isUpdate={isUpdate}
              rowsLimit={rowsLimit}
            />
          )}
        </div>
      </LayoutWrapper>
    </FormContext.Provider>
  );
};

export default FormTable;
