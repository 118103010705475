import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { images, notifyDanger, notifySuccess } from "../../actions/customFn";
import { useEffect, useState } from "react";
import { FaImage } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";
import { useSelector } from "react-redux";
import axios from "../../axios";
import SmallLoader from "../../utils/SmallLoader";

const ManagerEvalModal = ({
  modalShow,
  setModalShow,
  modalData,
  setIsUpdate,
}) => {
  const [step, setStep] = useState(1);
  const [allclaimedUsers, steAllClaimedUsers] = useState([]);
  const [currentId, setCurrentId] = useState("");
  const [fileData, setFileData] = useState([]);
  const [currentUsername, setCurrentUsername] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const profileData = useSelector((state) => state.profileData.userData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    steAllClaimedUsers(modalData.claimedUsers);
  }, [modalData]);

  useEffect(() => {
    if (currentId) {
      const existingFiles = modalData.files.find(
        (data) => data.userid._id === currentId
      );
      setFileData(existingFiles?.files || []);
    }
  }, [currentId]);

  // useEffect(() => {
  //   console.log(selectedIds);
  // }, [selectedIds]);

  const assignLeadinfo = () => {
    if (selectedIds.length === 0) {
      notifyDanger("Please select atleast one lead owner");
    } else {
      setIsLoading(true);
      const allIds = allclaimedUsers.map((data) => data.userid._id);
      const statusArr = [];

      for (let i = 0; i < allIds.length; i++) {
        if (selectedIds.length === 1) {
          if (selectedIds.includes(allIds[i])) {
            statusArr.push({
              userid: allIds[i],
              status: "Won",
              evaluatedBy: profileData._id,
            });
          } else {
            statusArr.push({
              userid: allIds[i],
              status: "Lose",
              evaluatedBy: profileData._id,
            });
          }
        } else {
          if (selectedIds.includes(allIds[i])) {
            statusArr.push({
              userid: allIds[i],
              status: "Shared",
              evaluatedBy: profileData._id,
            });
          } else {
            statusArr.push({
              userid: allIds[i],
              status: "Lose",
              evaluatedBy: profileData._id,
            });
          }
        }
      }
      const url = "/leads/manager-evaluate";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };
      const data = {
        evaluationArr: statusArr,
        email: modalData.email,
      };

      axios
        .put(url, data, config)
        .then((res) => {
          const { message } = res.data;
          notifySuccess(message);
          setIsUpdate((p) => !p);
          setIsLoading(false);
          setModalShow(false);
        })
        .catch((err) => {
          setIsLoading(false);

          notifyDanger(err?.response?.data?.message || "Some error occured!");
        });
    }
  };

  return (
    <Modal
      className=" manager-evaluation-modal bootstrap-modal-custom"
      show={modalShow}
      onHide={() => {
        setModalShow(false);
        setStep(1);
      }}
      centered
      size="lg"
    >
      <Modal.Body>
        <div className="create-module-modal-body">
          {/* <AiOutlineClose
            className="modal-close-btn"
            onClick={() => {
              setModalShow(false);
              setStep(1);
            }}
          /> */}
          {step === 1 && (
            <>
              <h2>Lead Evaluation</h2>
              <div className="row">
                {allclaimedUsers.map((data, i) => {
                  return (
                    <div className="col-4" key={i}>
                      <div className="manager-evaluation-modal-item">
                        <div className="manager-evaluation-modal-item-img">
                          <img
                            src={images["resources_file.png"]}
                            alt=""
                            className="w-100"
                          />
                        </div>
                        <div className="manager-evaluation-modal-item-info">
                          <h4>{data.userid.username} Files</h4>
                        </div>
                        <div className="manager-evaluation-modal-btn">
                          <button
                            onClick={() => {
                              setStep(2);
                              setCurrentId(data.userid._id);
                              setCurrentUsername(data.userid.username);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {step === 2 && (
            <div className="manager-evaluation-modal-step2">
              <div className="manager-evaluation-modal-step2-info">
                <h5>{currentUsername}</h5>
                <div className="manager-evaluation-modal-step2-options">
                  <div
                    className="manager-evaluation-modal-step2-option-div"
                    onClick={() => setIsOpen((p) => !p)}
                  >
                    <h6>Lead Owner</h6>
                    <FaAngleDown />
                  </div>
                  {isOpen && (
                    <div className="">
                      {allclaimedUsers.map((item, i) => {
                        return (
                          <div className="manager-evaluation-modal-step2-option-item">
                            <label htmlFor={`item-${i}`}>
                              {item.userid.username}
                            </label>
                            <input
                              type="checkbox"
                              id={`item-${i}`}
                              checked={selectedIds.includes(item.userid._id)}
                              onChange={() => {
                                if (
                                  selectedIds.find(
                                    (val) => val === item.userid._id
                                  )
                                ) {
                                  const filterItem = selectedIds.filter(
                                    (val) => val !== item.userid._id
                                  );
                                  setSelectedIds(filterItem);
                                } else {
                                  setSelectedIds([
                                    ...selectedIds,
                                    item.userid._id,
                                  ]);
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                {fileData.length > 0 ? (
                  fileData.map((data, i) => {
                    return (
                      <div className="col-4" key={i}>
                        <NavLink
                          className="manager-evaluation-modal-step2-item"
                          to={data}
                          target="_blank"
                        >
                          <FaImage /> <h4>File {i + 1}</h4>
                        </NavLink>
                      </div>
                    );
                  })
                ) : (
                  <h4 className="text-center text-black ">No Files Found!</h4>
                )}
              </div>
              <div className="manager-evaluation-modal-step2-btn">
                <button onClick={() => setStep(1)}>Back</button>
                <button onClick={assignLeadinfo} disabled={isLoading}>
                  {isLoading ? <SmallLoader color={"#fff"} /> : "Assign"}
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ManagerEvalModal;
