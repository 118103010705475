import React from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import { useLocation } from "react-router-dom";
import CompanyHierarchy from "../components/CompanyData/CompanyHierarchy";
import CompanyEmployee from "../components/CompanyData/CompanyEmployee";

const Company = () => {
  const { pathname } = useLocation();
  
  return (
    <>
      <LayoutWrapper>
        <Header />
        {pathname === "/company/hierarchy" && <CompanyHierarchy />}
        {pathname === "/company/employee" && <CompanyEmployee />}
      </LayoutWrapper>
    </>
  );
};

export default Company;
