import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import { FaChevronLeft } from "react-icons/fa6";
import { IoFilterOutline, IoFilterSharp } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import axios from "../../axios";
import moment from "moment";

const TicketQueryTable = () => {
  const [allTickets, setAllTickets] = useState([]);

  useEffect(() => {
    const url = `/qna/all-tickets`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setAllTickets(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="ticket_queries_outer_container m-2">
        <div className="ticket_queries_inner_container scrollbar-template">
          <div className="ticket_queries_header">
            <h2>
              <FaChevronLeft /> Ticket Queries
            </h2>

            <div className="ticket_queries_header_right">
              <span>
                <RiDeleteBinLine /> Delete
              </span>
              <span>
                <IoFilterSharp /> Filter
              </span>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>
                  {/* <input type="checkbox" /> */}
                  Sr No.
                </th>
                <th>Name</th>
                <th>Email ID</th>
                <th>Ticket ID</th>
                <th>Query</th>
                <th>Created At</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {allTickets.map((data, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td className="table_name">{data?.raisedBy?.username}</td>
                    <td>{data?.raisedBy?.email}</td>
                    <td>#{data.ticketId}</td>
                    <td className="ticket_table_query">{data.query}</td>
                    <td>{moment(data.createdAt).format("DD MM YYYY LT")}</td>
                    <td>
                      <TicketQuerieDropdown data={data} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="operation_offer_paginate ticket_queries_paginate">
            <ReactPaginate
              previousLabel={
                <span className="prev-button">
                  <CgArrowLongLeft />
                  Previous
                </span>
              }
              nextLabel={
                <span className="next-button">
                  Next
                  <CgArrowLongRight />
                </span>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketQueryTable;

const TicketQuerieDropdown = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <div className="dropdown">
      <span className="dropdown-button">
        <span className="dot" />
        {data.status}
        {/* <BiChevronDown size={18} /> */}
      </span>
      {isOpen && (
        <div className="dropdown-content">
          {/* Your dropdown content here */}
          <li>
            Pending <input type="radio" />
          </li>
          <li>
            Answered <input type="radio" />
          </li>
          <li>
            Solved <input type="radio" />
          </li>
        </div>
      )}
    </div>
  );
};
