import { MdOutlineAdd } from "react-icons/md";
// import {images}  from "../../asset/images"
import {
  confirmToast,
  images,
  notifyDanger,
  notifySuccess,
} from "../../actions/customFn";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ResourcesModal from "./ResourcesModal";
import axios from "../../axios";

const ResourcesFile = ({ folderArr, setIsUpdate, isLibrary }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div className="module_card_container">
        <div className="row scrollbar-template">
          {!isLibrary && (
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12  col-12 ">
              <div className="module_card_item module_card_create">
                <img
                  src={images["resources_upload.png"]}
                  alt=""
                  className="module_card_upload_img"
                />
                <h4>Resources Name</h4>

                <button onClick={() => setModalShow(true)}>
                  <MdOutlineAdd />
                  <span>add resources</span>
                </button>
              </div>
            </div>
          )}
          {/* {moduleList.length > 0 &&
          moduleList.map((data, i) => {
            return <ResourcesFileItem data={data} i={i} />;
            })} */}
          {folderArr.length > 0 &&
            folderArr.map((data, i) => {
              return (
                <ResourcesFileItem
                  data={data}
                  i={i}
                  setIsUpdate={setIsUpdate}
                  isLibrary={isLibrary}
                />
              );
            })}
        </div>
      </div>
      {modalShow && (
        <ResourcesModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          setIsUpdate={setIsUpdate}
        />
      )}
    </>
  );
};

export default ResourcesFile;

const ResourcesFileItem = ({ data, i, setIsUpdate, isLibrary }) => {
  const navigate = useNavigate();

  const deleteFolder = () => {
    const url = `/sales/delete-resource-folder/${data._id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .delete(url, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        notifyDanger(err?.response?.data?.message || "Some Error Occured");
        console.log(err);
      });
  };
  return (
    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12  col-12 " key={i}>
      <div className="module_card_item">
        <div className="module_card_item_file_box">
          <img src={images["resources_file.png"]} alt="" />
        </div>
        {/* <h4>{data.title}</h4> */}
        <h4>{data.folder_name}</h4>

        {/* <h6>{data.files.length} Files</h6> */}
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ gap: "10px " }}
        >
          <button
            onClick={() => {
              isLibrary
                ? navigate(`/library/upload/${data._id}`)
                : navigate(`/resources/upload/${data._id}`);
            }}
          >
            View
          </button>

          {!isLibrary && (
            <RiDeleteBin5Fill
              onClick={() =>
                confirmToast(
                  "Are you want to delete this folder?",
                  deleteFolder
                )
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
