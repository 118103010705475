import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import axios from "../../axios";
import { useSelector } from "react-redux";
import Loader from "../../utils/Loader";
const EvidenceModal = ({ modalShow, setShowModal, userData, setIsUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateLead = async (e) => {
    setIsLoading(true);
    const url = `/leads/update-lead`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const formData = new FormData();
    formData.append("email", userData.email);
    formData.append("file", e.target.files[0]);

    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        if (setIsUpdate) {
          setIsUpdate((p) => !p);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        notifyDanger(err?.response?.data?.message || "Some error occured!");
      });
  };
  return (
    <Modal
      centered
      className="bootstrap-modal-custom comment-modal evidence_modal_container"
      show={modalShow}
      onHide={() => setShowModal(false)}
    >
      {isLoading ? (
        <Loader Height={"40vh"} />
      ) : (
        <Modal.Body>
          <h3>Upload Your evidences</h3>
          <label className="evidence_modal_label">Upload</label>
          <label className="evidence_modal_file" htmlFor="fileID">
            <p>Browse Your device</p>
            <span>Or</span>
            <span>Drag & Drop here</span>
          </label>
          <input
            className="d-none"
            id="fileID"
            type="file"
            accept="image/*"
            onChange={updateLead}
          />
        </Modal.Body>
      )}
    </Modal>
  );
};

export default EvidenceModal;
