import { notifyDanger, notifySuccess } from "./customFn";
import { setProfileData } from "../slice/profileSlice";
import axios from "../axios";
import { setLogin, setLogout } from "../slice/authSlice";

export const asyncLogin = (
  formData,
  navigate,
  initialState,
  setIsLoading,
  setIsLoggedInDevice
) => {
  return (dispatch) => {
    const url = "/auth/login";
    axios
      .post(url, formData)
      .then((res) => {
        const { message, data, token } = res.data;
        dispatch(setLogin());
        localStorage.setItem("oeson_auth", token);
        localStorage.setItem("oeson_role", data.typeOfUser);
        localStorage.setItem("oeson_id", data._id);

        notifySuccess(message);
        setIsLoading(false);
        initialState();
        navigate("/");
      })
      .catch((err) => {
        setIsLoading(false);
        const status = err?.response?.data?.status || "";
        if (status === 419 && setIsLoggedInDevice) {
          setIsLoggedInDevice(true);
        }
        console.log(err);
        notifyDanger(err?.response?.data?.message || "Some Error Occured!");
      });
  };
};

export const asyncLogOut = (navigate) => {
  return (dispatch) => {
    const url = "/auth/logout";
    const data = {
      token: localStorage.getItem("oeson_auth"),
      id: localStorage.getItem("oeson_id"),
    };
    axios
      .post(url, data)
      .then((res) => {
        localStorage.removeItem("oeson_auth");
        localStorage.removeItem("oeson_role");
        localStorage.removeItem("oeson_id");
        dispatch(setLogout());
        notifySuccess("Logout successfully!");
        if (navigate) {
          navigate("/");
        }
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err);
        // notifyDanger(err.response.data.message);
      });
  };
};

export const asyncProfileData = (setIsLoading) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const url = "/user/myProfile";
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        dispatch(setProfileData(data));
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (setIsLoading) {
          setIsLoading(false);
        }
        // notifyDanger(err.response.data.message || err.response);
      });
  };
};

export const asyncUpdateProfileDetails = (data, initialState, setIsUpdate) => {
  return (dispatch) => {
    const url = "/user/updateProfile";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        initialState();
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        setIsUpdate((p) => !p);

        notifyDanger(err.response.data.message || "some error occured");
      });
  };
};

export const asyncUpdateProfilePassword = (data, initialState, setIsUpdate) => {
  return (dispatch) => {
    const url = "/user/updatePassword";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    console.log(data);
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        initialState();
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        setIsUpdate((p) => !p);

        notifyDanger(err.response.data.message || "some error occured");
      });
  };
};
