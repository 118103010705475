import React, { useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { IoIosSearch } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import axios from "../../axios";
import SmallLoader from "../../utils/SmallLoader";

const ResourcesNav = ({ setIsUpdate }) => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const uploadFile = (e) => {
    setIsLoading(true);
    const url = "/sales/add-resource";
    const formData = new FormData();
    formData.append("id", id);
    formData.append("image", e.target.files[0]);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notifyDanger(err?.response?.data?.message || "Some Error Occured");
        console.log(err);
      });
  };

  return (
    <>
      <div className="resources_nav">
        <div className="resources_nav_header">
          <span>
            <FiChevronLeft size={30} />
            Resources
          </span>
          <div className="resources_nav_input">
            <IoIosSearch className="resources_nav_search_icon" />
            <input type="text" placeholder="Search" />
          </div>
        </div>

        <ul className="resources_nav_tabs">
          <li
            className={location.pathname.includes("resources") ? "active cursor-pointer" : " cursor-pointer"}
            onClick={() => navigate("/resources")}
          >
            My Resources
          </li>
          <li
            className={location.pathname.includes("library") ? "active cursor-pointer" : " cursor-pointer"}
            onClick={() => navigate("/library")}
          >
            Library
          </li>

          {location.pathname === `/resources/upload/${id}` && (
            <>
              <label htmlFor="upload" className="cursor-pointer">
                {isLoading ? <SmallLoader color={"#024c87"} /> : "upload files"}
              </label>
              <input
                onChange={uploadFile}
                type="file"
                id="upload"
                className="d-none"
              />
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default ResourcesNav;
