import React, { useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import { IoIosSearch } from "react-icons/io";
import { BiFilterAlt } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import OperationOfferLetterTable from "../components/OperationOfferTable/OperationOfferLetterTable";
import OperationUploadModal from "../components/OperationOfferTable/OperationUploadModal";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";

const OperationOffer = () => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <LayoutWrapper>
        <Header />
        <div className="container">
          <div className="operation_offer_header">
            <div className="operation_offer_input">
              <IoIosSearch className="search_icon" />
              <input type="text" placeholder="Search Task" />
            </div>
            <span>
              <BiFilterAlt className="filter_icon" /> Filter
            </span>
            <div className="operation_offer_buttons">
              <button onClick={() => setModal(true)} className="upload">
                upload
              </button>
              <button className="add_new">Add New</button>
            </div>
          </div>

          <div className="operation_offer_letter_table">
            <div className="operation_offer_letter_header">
              <h2>Students</h2>
              <BsThreeDotsVertical fontSize={20} className="three-dots" />
            </div>

            <OperationOfferLetterTable />

            <div className="operation_offer_paginate">
              <ReactPaginate
                previousLabel={
                  <span className="prev-button">
                    <CgArrowLongLeft />
                    Previous
                  </span>
                }
                nextLabel={
                  <span className="next-button">
                    Next
                    <CgArrowLongRight />
                  </span>
                }
              />
            </div>
          </div>
        </div>
      </LayoutWrapper>

      {modal && <OperationUploadModal modal={modal} setModal={setModal} />}
    </>
  );
};

export default OperationOffer;
