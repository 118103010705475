import React, { useRef, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  confirmToast,
  images,
  notifyDanger,
  notifySuccess,
} from "../../actions/customFn";
import moment from "moment";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "../../axios";
import { FaFilePdf, FaLink } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaRegImage } from "react-icons/fa6";
import { FaFolder } from "react-icons/fa";
import { IoDocumentTextSharp } from "react-icons/io5";
import { FaFileVideo } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";

const ResourcesView = ({ fileList, setIsUpdate }) => {
  // const [show, setShow] = useState(false);
  const { id } = useParams();
  const { pathname } = useLocation();
  // const optionRef = useRef(null);
  const deleteResource = (fileID) => {
    const url = `/sales/remove-resource`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const data = {
      folderId: id,
      fileId: fileID,
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        notifyDanger(err?.response?.data?.message || "Some Error Occured");
        console.log(err);
      });
  };

  const FileFormatObj = {
    pdf: <FaFilePdf style={{ fill: "#024c87", fontSize: "28px " }} />,
    xl: <RiFileExcel2Fill style={{ fill: "#024c87", fontSize: "28px " }} />,
    jpg: <FaRegImage style={{ fill: "#024c87", fontSize: "28px " }} />,
    png: <FaRegImage style={{ fill: "#024c87", fontSize: "28px " }} />,
    svg: <FaRegImage style={{ fill: "#024c87", fontSize: "28px " }} />,
    folder: <FaFolder style={{ fill: "#024c87", fontSize: "28px " }} />,
    text: (
      <IoDocumentTextSharp style={{ fill: "#024c87", fontSize: "28px " }} />
    ),
    mp4: <FaFileVideo style={{ fill: "#024c87", fontSize: "28px " }} />,
  };

  // const handleToggle = () => {
  //   setShow(!show);
  // };

  const [hover, setHover] = React.useState(false);
  return (
    <>
      <div className="resources_upload_container">
        <div className="resources_upload_tab">
          {/* <input type="checkbox" /> */}
          <div className="resources_type">Sr.No.</div>
          <label className="resources_type">Resource Type</label>
          <label className="resources_name">Resource Name</label>
          <label className="resources_date">Created At</label>
          {/* <label className="resources_size">File Size</label> */}
        </div>

        {fileList?.files?.map((data, i) => {
          return (
            <div
              // onMouseEnter={() => setHover(true)}
              // onMouseLeave={() => setHover(false)}
              className="resources_upload_tab_body"
            >
              <div className="serial_no">
                {hover ? <input type="checkbox" /> : i + 1}
                {/* {i + 1}. */}
              </div>

              <span>
                {/* <img src={images["resources_file2.png"]} alt="" /> */}
                {FileFormatObj[data?.filename?.split(".")[1]] ||
                  FileFormatObj["folder"]}
              </span>
              <NavLink target="_blank" to={data.fileurl}>
                {data.filename}
              </NavLink>
              <span className="resources_date">
                {moment(data.date).format("DD MMM YYYY LT")}
              </span>
              {/* <span>20 MB</span> */}

              {/* {pathname.includes("resources") && (
                <span>
                  <RiDeleteBinLine
                    className="delete_icon cursor-pointer"
                    onClick={() =>
                      confirmToast("Are you want to delete this file?", () => {
                        deleteResource(data._id);
                      })
                    }
                  />
                </span>
              )} */}

              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "50px",
                }}
              >
                <ResourcesOption
                  deleteResource={deleteResource}
                  data={data}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ResourcesView;

const ResourcesOption = ({ deleteResource, data }) => {
  const [show, setShow] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(data.fileurl);
    notifySuccess("link copied successfully");
  };
  return (
    <div className="resources_edit_btn">
      <BsThreeDotsVertical size={20} onClick={() => setShow((p) => !p)} />
      {show ? (
        <div className="resources_dropdown">
          <button onClick={handleCopy}>
            <FaLink />
            Copy link
          </button>
          <button
            onClick={() => {
              confirmToast("Are you want to delete this work?", () => {
                // setIsLoading(true);
                deleteResource(data._id);
              });
            }}
          >
            <RiDeleteBinLine />
            Delete
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
