import React from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import TicketQueryTable from "../components/TicketQuery/TicketQueryTable";

const TicketQuery = () => {
  return (
    <>
      <LayoutWrapper>
        <Header />
        <TicketQueryTable />
      </LayoutWrapper>
    </>
  );
};

export default TicketQuery;
