import React, { useContext, useEffect, useState } from "react";
import axios from "../../axios";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import Loader from "../../utils/Loader";
import { FormContext } from "../../pages/FormTable";
const OgtipTable = ({ searchInp, isUpdate  , rowsLimit}) => {
  const DUMMY_DATA = [
    {
      name: "Test User",
      email: "test@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 2",
      email: "test2@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 3",
      email: "test3@example.com",
      phone: "+92 1234567890",
    },
  ];

  const [enquireUsData, setEnquireUsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const { filterOptions } = useContext(FormContext);

  useEffect(() => {
    setIsLoading(true);
    const url = "/freetrial";
    let paramObj = {
      page: currentPage,
    };

    if (searchInp !== "") {
      paramObj.searchTerm = searchInp.trim();
    }

    if(rowsLimit){
      paramObj.limit = rowsLimit 
    }else{
      paramObj.limit = 10 
    }



    paramObj = { ...paramObj, ...filterOptions };

    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

 

    axios
      .get(url, config)
      .then((res) => {
        const { data, totalPages } = res.data;
        setEnquireUsData(data);
        setTotalPages(totalPages);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setIsLoading(false);
      });
  }, [currentPage, isUpdate]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  
  useEffect(() => {
    setCurrentPage(1);
  }, [isUpdate]);

  return (
    <>
      <div className="module_student_table table-responsive scrollbar-template">
        {isLoading ? (
          <Loader Height={"40vh"} />
        ) : (
          <table className="table-template-style ">
            <thead>
              <tr>
                <th scope="col" className="table-first-element">
                  Sr no.
                </th>

                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone no.</th>
                <th scope="col">Domain</th>
                <th scope="col">createdAt</th>
              </tr>
            </thead>
            <tbody>
              {enquireUsData.map((data, i) => {
                return <TableItem data={data} index={i} currentPage={currentPage} />;
              })}
            </tbody>
          </table>
        )}

        {/* <h4 className="text-center text-black mt-4">No Students Found!</h4> */}
      </div>
      {!isLoading && (
        <div className="react_paginate_Div">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< previous"
            activeClassName={"active"}
            renderOnZeroPageCount={null}
            forcePage={currentPage !== 1 ? currentPage - 1 : ""}
          />
        </div>
      )}
    </>
  );
};

export default OgtipTable;

const TableItem = ({ index, data , currentPage }) => {
  return (
    <tr key={index}>
      <td> {(currentPage - 1) * 10 + index + 1}</td>
      <td>{data.name}</td>
      <td>{data.email}</td>
      <td>{data.phone_no}</td>
      <td>{data.course}</td>
      <td>{moment(data.createdAt).format("DD MMM YYYY LT")}</td>
    </tr>
  );
};
